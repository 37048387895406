//
// variables.scss
//


@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700');

// Color variables
$white:                     #ffffff;
$black:                     #000000;

$custom:                    #ff8700;

$primary:                   #005bea;
$success:                   #12925b;
$info:                      #3bc3e9;
$warning:                   #ffbb44;
$danger:                    #ea553d;
$dark:                      #343a40;
$muted:                     #cccccc;
$light:                     #f3f3f3;
$body-color:                #212529;


// Body Background
$body-bg:                   #f5f5f5;

$font-family-base:            'Rubik', sans-serif;